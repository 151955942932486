<template>
  <main>
    <PageTitle title="通知內容範本" btn="新增" @btnClick="onCreate" />
    <div class="flex flex-col gap-[12px] mb-[30px]">
      <ElInputWrapper>
        <SearchTextInput style="width: 692px" :value.sync="search.name" placeholder="輸入範本名稱" @refresh="refresh(true)" />
      </ElInputWrapper>
      <div class="flex" style="gap: 12px">
        <ElInputWrapper>
          <BaseElSelect v-model="search.isDefault" style="width: 340px" placeholder="選擇範本類型" clearable @change="refresh(true)">
            <BaseElSelectOption
              v-for="option in contentTemplateTypeOptions"
              :key="option.value"
              :value="option.value"
              :label="option.label"
            />
          </BaseElSelect>
        </ElInputWrapper>

        <ElInputWrapper>
          <BaseElSelect v-model="search.useType" style="width: 340px" placeholder="選擇範本用途" clearable @change="refresh(true)">
            <BaseElSelectOption
              v-for="option in useTypeOptions"
              :key="option.value"
              :value="option.value"
              :label="option.label"
            />
          </BaseElSelect>
        </ElInputWrapper>
      </div>
    </div>
    <NotificationContentTemplateTable v-loading="loading.table" :tableData="tableData" @refresh="refresh(false)" />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </main>
</template>

<script>
// page - 通知內容範本
import { defineComponent, onMounted, ref, reactive, onActivated } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
import PageTitle from '@/components/Title/PageTitle.vue'
import NotificationContentTemplateTable from './components/NotificationContentTemplateTable.vue'
import SearchTextInput from '@/components/Input/SearchTextInput.vue'
import { targetTypeConfig, contentTemplateTypeOptions, useTypeOptions } from '@/config/progressNotification'
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import { GetNotifyContentTemplate, GetNotifyContentTemplateCount } from '@/api/progressNotification'

export default defineComponent({
  name: 'NotificationContentTemplateList',
  components: {
    PageTitle,
    NotificationContentTemplateTable,
    SearchTextInput,
    ElInputWrapper,
  },
  setup (props) {
    const router = useRouter()
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
    } = useTable()

    const search = reactive({
      name: null,
      isDefault: null,
      useType: null,
    })

    const onCreate = () => {
      router.push({ name: 'ProgressNotificationContentTemplateEdit' })
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        name: search.name || undefined,
        isDefault: search.isDefault === 'default' ? true : search.isDefault === 'create' ? false : undefined,
        types: search.useType || undefined,
      }
      await Promise.allSettled([
        fetchData(GetNotifyContentTemplate, payload),
        fetchDataCount(GetNotifyContentTemplateCount, payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(() => {
      refresh()
    })
    onActivated(() => {
      refresh()
    })
    return {
      loading,
      tableData,
      tableOptions,
      tableDataCount,
      onCreate,
      refresh,
      search,
      targetTypeConfig,
      contentTemplateTypeOptions,
      useTypeOptions,
    }
  },
})
</script>
